<div *ngIf="order" class="card card-nb h-100 order" (click)="downloadAttachment()">
    <div class="card-body pb-0">
      <div class="d-flex justify-content-between">
        <div class="p-0 m-0 font-weight-bold">
            <span>{{order.name}}&nbsp;</span>
        </div>
        <div *ngIf="isPartnerOrder && order.state === 'sent' && !order.expired" class="custom-dropdown-menu ml-3" ngbDropdown display="dynamic" placement="bottom-right">
          <div class="btn bg-transparent p-0 m-0 hide-dropdown-icon" id="dropdownBasic2" ngbDropdownToggle>
            <i class="fas fa-ellipsis-v red icon-size-bigger navbar-profile-icon"></i>
          </div>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
            <div class="cursor-pointer dropdown-item" (click)="resendQuotation()">
                        <span class="d-flex">
                          <i class="fa fa-backward red icon-size-big mr-2"></i>
                          <span>{{ 'resend_quotation' | i18next }}</span>
                        </span>
            </div>
            <div class="cursor-pointer dropdown-item" (click)="cancelQuotation()">
                        <span class="d-flex">
                          <i class="fa fa-ban red icon-size-big mr-2"></i>
                          <span>{{ 'cancel_quotation' | i18next }}</span>
                        </span>
            </div>
          </div>
        </div>
      </div>
      <div class="subinformations">
        <div class="d-flex flex-column flex-sm-row justify-content-between">
          <div class="p-0 m-0">
            <div class="d-flex align-items-center align-self-center">
              <i class="far fa-user red"></i>
              <div class="ml-1">{{order.customer_name}}<span *ngIf="order.appliance_id"></span></div>
            </div>
            <div>
              <ng-container *ngIf="isRelevantProduct(order.product); else staticText">
                [<a routerLink="/subscriptions/{{order.appliance_id}}">{{order.appliance_id}}</a>] {{order.appliance_name}}
              </ng-container>
              <ng-template #staticText>
                [{{order.appliance_id}}] {{order.appliance_name}}
              </ng-template>
            </div>
          </div>
          <div>{{order.date | localized_date}}</div>
        </div>
      </div>
      <div class="subinformations mt-3">
        <div class="d-flex flex-column">
          <div class="d-flex flex-column flex-sm-row justify-content-between">
            <div class="p-0 m-0" *ngIf="order.product">
              {{ order.quantity }} x {{ order.getProductNameForPreview() }}
            </div>
            <div class="text-nowrap font-weight-bold"> {{order.amount_untaxed | localized_currency}}</div>
          </div>
          <div class="d-flex flex-row-reverse ml-auto p-0 m-0 mt-2">
            <i *ngIf="order.attachment"
               class="dlbutton fas fa-download features-icon red icon-size-bigger "
               style="cursor: pointer">
            </i>
          </div>

        </div>
      </div>
    </div>
    <div *ngIf="isPartnerOrder" class="row subinformations pb-1 mr-2">
      <div class="d-flex ml-auto align-items-end flex-column mt-auto">
        <div class="mt-auto" style="min-height: 22px;">
              <span [class]="cssClass" class="mr-2 status-text">
                <span *ngIf="cssClass.active ">{{ 'quotation_status_active' | i18next }}</span>
                <span *ngIf="cssClass.dead">{{ 'quotation_status_expired' | i18next }}</span>
                <span *ngIf="cssClass.pending && order.state === 'sent' && state === 'sent'">{{ 'quotation_status_customer_approval' | i18next }}</span>
                <span *ngIf="cssClass.pending && order.state === 'sale' && state === 'sent'">{{ 'quotation_status_pending_approval' | i18next }}</span>
              </span>
        </div>
      </div>
    </div>
    <div *ngIf="isPartnerOrder"  class="m-0 pt-1 status-bg" [class]="cssClass" ></div>
</div>
