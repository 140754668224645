<div class="card card-nb h-100 hover-effect" *ngIf="customer" (click)="showDetails()" >
  <div class="card-body pb-0">
    <div class="row mb-2">
      <div class="col-10 highlighted font-weight-bold huline" (click)="showDetails()">
        <span>{{customer.name}}</span>
      </div>
      <div class="col-2">
        <div class="float-right">
          <div class="badge-icon-container mr-2">
            <i class="fas fa-cloud red icon-size-bigger"></i>
            <!-- <span class="badge badge-pill badge-primary" style="right:16px; background-color: red; color: white;" >{{ customer.appliance_ids.length }}</span> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row subinformations">
      <div class="col-10">
        <div>{{customer.street}}&nbsp;{{customer.street2}}</div>
        <div>{{customer.zip}}&nbsp;{{customer.city}}</div>
        <div>{{customer.country.code}}&nbsp;{{customer.country.name}}</div>
        <!-- <div class="red">{{customer.appliance_ids.length}}&nbsp;{{customer.appliance_ids.length == 1 ? "Subscription" : "Subscriptions"}}</div> -->
      </div>
      <div class="col-2">
        <div class="d-flex align-items-end flex-column h-100">
          <div class="mb-auto">
            &nbsp;
          </div>
          <div>
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
