import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse, HttpResponse,
} from '@angular/common/http';

import {Observable} from 'rxjs';
import {MessageService} from 'primeng/api';
import {I18NextService} from 'angular-i18next';
import {tap} from 'rxjs/operators';
import {AuthService} from '../auth/auth.service';
import {MaintenanceService} from './maintenance/maintenance.service';
import {Model} from '../app.model';


@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(
    private messageService: MessageService,
    private i18next: I18NextService,
    private auth: AuthService,
    private maintenanceService: MaintenanceService,
    private m: Model
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.handleRequest(request);
    return next.handle(request).pipe(
      tap({next: _ => {}, error: err => {
          if (err instanceof HttpErrorResponse) {
            this.handleErrorResponse(err);
          }
    }}));
  }

  handleErrorResponse(err: HttpErrorResponse) {
    if  (err.url === "https://www.cloudflare.com/cdn-cgi/trace") {
      return;
    }
    if (err.status === 503) {
      this.maintenanceService.scheduledMaintenance(err?.error?.status_url || '');
      return;
    }

    if (err.status === 401 && ['user_disabled', 'user_not_active'].includes(err.error?.message)) {
      this.m.account.setOdooDisabled();
    }

    if (err.status === 401 && err.error?.message !== '2fa_required') {
      // 401 from odoo will end up in a auth failure page
      this.m.account.setAuthorizationFailed();
      this.auth.clearAuthCookie();
      return;
    }

    this.messageService.add({severity: 'error',
      summary: this.i18next.t('error') as string,
      detail: this.i18next.t(err.error.message) as string});
  }

  /**
   * Handles request to the server
   * E.g. reset idle timer
   * @param request
   * @private
   */
  private handleRequest(request: HttpRequest<any>) {
    const { url } = request;

    // We need to reset the idle timer only when we use our own API on /services, otherwise keycloak will refresh this too
    if (/^\/services/.test(url)) {
      this.auth.resetIdleTimer();
    }
  }
}
