import {AutorunComponent} from '@myshared/autorun.component';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Order} from '../order.model';
import {OrderService} from '../order.service';
import {Model} from "../../app.model";
import {Router} from "@angular/router";
import {OrderState} from "../../buy/buy.model";
import {MessageService} from "primeng/api";
import {I18NextService} from "angular-i18next";

@Component({
    selector: 'app-order-card',
    templateUrl: './order-card.component.html',
})
export class OrderCardComponent extends AutorunComponent {

    constructor(
      private orderService: OrderService,
      private m: Model,
      private router: Router,
      private messageService: MessageService,
      private i18next: I18NextService
    ) {
        super();
    }

    private _order: Order;

    @Output() public refresh = new EventEmitter<void>();

    @Input()
    public state: OrderState;

    @Input()
    public set order(o: Order) {
        this._order = o;
    }

    public get order(): Order {
        return this._order;
    }

    public downloadAttachment() {
      if (this._order.attachment) {
        this.orderService.downloadFromUrl(this.order);
      }
    }

    public get isPartnerOrder() {
      return this.m.account.currentUser.company_id !== this._order.customer_id;
    }

    public get cssClass() {
      return {
        'pending': !this._order.expired && (this._order.state === 'sent'
          || (this._order.state === 'sale' && this.state === 'sent')),
        'dead': this._order.expired && this._order.state === 'sent',
        'active': this._order.state === 'sale' && this.state === 'sale'
      };
    }

    public isRelevantProduct(product: string): boolean {
      const keywords = ['PC-ONE', 'PC-CANCEL', 'PC-CREDIT'];
      return keywords.some(substring => product.includes(substring));
    }

    public resendQuotation() {
      if (this._order.expired) return;
      this.orderService.resendQuotation(this._order.name).subscribe(r => {
        this.messageService.add({severity: 'success',
          summary: this.i18next.t('resend_quotation_success_headline') as string,
          detail: this.i18next.t('resend_quotation_success_text') as string});
      });
    }

    public cancelQuotation() {
      if (this._order.expired) return;
      this.orderService.cancelQuotation(this._order.name).subscribe(r => {
        this.messageService.add({severity: 'success',
          summary: this.i18next.t('cancel_quotation_success_headline') as string,
          detail: this.i18next.t('cancel_quotation_success_text') as string});
        this.refresh.emit();
      });

    }
}
